/*
  Can be wrapped around an input element to show a `clear` button
  when the input has a value.

  The input element should ideally be direct child of the component.

  ```
    <drb-clearable-input>
      <input type="text" />
    </drb-clearable-input>
  ```

  Can tweak the positioning of the button via `--clearable-input-offset` CSS variable.
*/

import { LitElement, unsafeCSS, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import closeIcon from '~/assets/icons/v2-close.svg?raw';
import styles from './drb-clearable-input.scss?inline';

@customElement('drb-clearable-input')
class DrbClearableInput extends LitElement {
  static styles = unsafeCSS(styles);
  inputEl: HTMLInputElement | null = this.querySelector('input');

  @state()
  active = false;

  connectedCallback() {
    super.connectedCallback();
    if (!this.inputEl) return;

    this.inputEl.addEventListener('input', this._updateVisibility.bind(this));
    this.inputEl.addEventListener('change', this._updateVisibility.bind(this));
  }

  private _updateVisibility() {
    this.active = !!this.inputEl?.value;
  }

  private _clear() {
    if (this.inputEl) {
      this.inputEl.value = '';
      this.inputEl.dispatchEvent(new Event('input', { bubbles: true }));
      this.active = false;
    }
  }

  render() {
    return html`
      <slot></slot>

      <button
        class="clear-btn"
        @click="${this._clear}"
        ?hidden="${!this.active}"
        aria-label="Clear"
      >
        ${unsafeHTML(closeIcon)}
      </button>
    `;
  }
}

export { DrbClearableInput };
